import Vue from 'vue'
import moment from 'moment'

const timezone = process.env.VUE_APP_TIME_ZONE

Vue.mixin({
  methods: {
    dateFormatWithTime: date => moment(date).utcOffset(timezone).format('DD/MM/YYYY, hh:mma'),
    dateFormat: date => moment(date).utcOffset(timezone).format('DD/MM/YYYY'),
    dateFormatFromTimeString: time => moment(time, 'HH:mm').utcOffset(timezone).format('hh:mma'),
    dateFormatFromTimeStringWithoutTimezone: time => moment(time, 'HH:mm').format('hh:mma'),
    dateFormatFromTimeStringWithoutTimezoneMandarin: time => moment(time, 'HH:mm').locale('zh_cn').format('hh:mma'),
    dateFormatWithoutTimezoneMandarin: date => moment(date, 'DD/MM/YYYY').locale('zh_cn').format('LL'),
    timeFormatFromDateString: date => moment(date).utcOffset(timezone).format('hh:mma'),
    twentyFourHourTimeFormatFromDateString: date => moment(date).utcOffset(timezone).format('HH:mm'),
    dateFormatWithTwentyFourHourTimeFormatFromDateString: date => moment(date).utcOffset(timezone).format('DD/MM/YYYY, HH:mm'),
    standardDateFormatWithTwentyFourHourTimeFormatFromDateString: date => moment(date).utcOffset(timezone).format('DD/MM/YYYY, H:i'),
    standardDateFormat: date => moment(date).utcOffset(timezone).format('YYYY-MM-DD'),
    fullMonthDateFormat: date => moment(date, 'DD/MM/YYYY').utcOffset(timezone).format('DD MMMM YYYY'),
    dateFormatWithDay: date => moment(date, 'DD/MM/YYYY').utcOffset(timezone).format('dddd, MMMM DD, YYYY'),
    dateFormatWithShortDay: date => moment(date).utcOffset(timezone).format('ddd DD/MM/YYYY hh:mma'),
    humanReadableFormat: date => moment(date).utcOffset(timezone).fromNow(),
    // this does not work in safari becasue of lookbehind support
    // numberFormat: number => (number ? parseFloat(number).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : '0.00'),
    numberFormat: number => {
      if (number) {
        const parts = parseFloat(number).toFixed(2).split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return parts.join('.')
      }
      return '0.00'
    },
    floatNumber: number => {
      if (number) {
        return parseFloat(number).toFixed(2)
      }
      return '0.00'
    },
    isMobile: () => {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      }
      return false
    },
    addAlpha: (color, opacity) => {
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
      return color + _opacity.toString(16).toUpperCase()
    },
  },
})
