/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import users from './routes/users'
import purchase from './routes/purchase'
import directory from './routes/directory'
import operation from './routes/operation'
import appointment from './routes/appointment'
import inventory from './routes/inventory'
import budget from './routes/budget'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (from.path === to.path && ['appointment-bookings-show', 'purchasing-purchase-request-show', 'inventory-inventory-request-view'].includes(to.name)) {
      return {}
    }
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', meta: { redirectIfLoggedIn: true } },
    ...apps,
    ...dashboard,
    ...pages,
    ...users,
    ...purchase,
    ...directory,
    ...operation,
    ...appointment,
    ...inventory,
    ...budget,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const userData = getUserData()

  store.commit('breadcrumb/ADD_BREADCRUMB', to.meta.breadcrumb)

  if (to.meta.frontend) {
    return next()
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const path = userData.userRedirectURL || '/dashboard'
    // next({ name: 'dashboard' })
    return next({ path })
  }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    if (to.name === 'dashboard') {
      const path = userData.userRedirectURL || '/not-authorized'
      // next({ name: 'dashboard' })
      return next({ path })
    }

    if (['not-authorized', 'directory-my-profile', 'directory-my-profile-update', 'directory-my-profile-change-password'].includes(to.name)) return next()
    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(to => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

  if (to.meta.title) {
    document.title = `${to.meta.title} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`
  } else {
    document.title = process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'
  }
})

export default router
